import { signOut, useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { gql, useQuery } from 'urql';
import CustomerModel from '../../models/customer/CustomerModel';
import { Customer } from '../../types/shopify/customers/Customer.type';

const getCustomerCompany = gql`
  query GetCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      lastName
      tags
    }
  }
`;

export interface IUseCustomer {
  /**
   * お客様情報の一意キー
   */
  customerAccessToken: string;
}

/**
 * [名前] お客様会社名の取得
 * [概要] 所属会社名を取得する
 * @param IUseCustomer お客様情報の一意キー
 */
const useCustomerCompany = (customerAccessToken: IUseCustomer) => {
  const { data: sessiondata } = useSession();
  const [{ data, fetching, error, stale }] = useQuery<{
    customer: Customer;
  }>({
    query: getCustomerCompany,
    variables: customerAccessToken,
    requestPolicy: 'cache-and-network',
  });

  // カスタマーの情報が変わった時、ログイン画面に戻る
  useEffect(() => {
    if (!fetching && !stale && sessiondata?.user.customer) {
      const customer = new CustomerModel(data?.customer);
      if (
        sessiondata?.user.customer.grade !== customer.grade ||
        sessiondata.user.customer.payType !== customer.payType
      ) {
        signOut({
          callbackUrl: '/login',
        });
        localStorage.removeItem('CHECKOUT_ID');
      }
    }
  }, [stale, data, fetching, sessiondata?.user.customer]);

  return [
    {
      data,
      fetching,
      error,
    },
  ];
};

export default useCustomerCompany;
