import { PAYMENT_METHOD, PAYMENT_TYPE } from '../../constants/MyPageConstants';
import { Customer } from '../../types/shopify/customers/Customer.type';
import AddressModel from './AddressModel';
import { PhoneNumberModel } from './PhoneNumberModel';

export default class CustomerModel {
  private data?: Customer;

  public memberno? = '';

  public grade? = '';

  public usance? = '';

  public pic? = '';

  public payType? = '';

  public defaultPay? = '';

  private defaultAddressModel: AddressModel;

  private addressModels: AddressModel[] = [];

  private phoneNumber?: PhoneNumberModel;

  constructor(customer?: Customer) {
    this.data = customer;
    customer?.tags?.forEach((t) => {
      const [key, value = undefined] = t.split(':');
      if (key === 'mno') {
        this.memberno = value;
      } else if (key === 'grade') {
        this.grade = value;
      } else if (key === 'usance') {
        this.usance = value;
      } else if (key === 'pic') {
        this.pic = value;
      } else if (key === 'payType') {
        this.payType = value;
      } else if (key === 'defaultPay') {
        this.defaultPay = value;
      }
    });
    this.defaultAddressModel = new AddressModel(customer?.defaultAddress);
    customer?.addresses?.nodes.forEach((node) => {
      this.addressModels.push(new AddressModel(node));
    });

    this.phoneNumber = this.data?.phone
      ? new PhoneNumberModel(this.data?.phone)
      : undefined;
  }

  /**
   * id(?以降は削除)
   */
  get id() {
    return this.data?.id?.split('?')[0];
  }

  /**
   * 顧客情報の名を名前とする
   */
  get name() {
    return this.data?.firstName;
  }

  /**
   * 顧客情報の姓を会社名とする
   */
  get company() {
    return this.data?.lastName;
  }

  /**
   * メールアドレス
   */
  get email() {
    return this.data?.email;
  }

  get address() {
    return this.defaultAddressModel;
  }

  set address(address: AddressModel) {
    this.defaultAddressModel = address;
  }

  get nonDefaultAddresses() {
    return this.addressModels.filter(
      (model) => !model.equals(this.defaultAddressModel.id),
    );
  }

  get hasError() {
    const regExp = /^gid:\/\/shopify\/Customer\/\d/;
    if (regExp.test(this.id || '')) {
      return false;
    }
    return true;
  }

  get displayPhone() {
    return this.phoneNumber?.displayPhoneNumber || '';
  }

  get payMethod() {
    switch (this.payType) {
      case PAYMENT_TYPE.bill:
        return PAYMENT_METHOD.bill;
      case PAYMENT_TYPE.credit:
        return PAYMENT_METHOD.credit;
      case PAYMENT_TYPE.bank:
        return PAYMENT_METHOD.bank;
      case PAYMENT_TYPE.all:
        return this.defaultPay;
      default:
        return '';
    }
  }

  get payDate() {
    return this.getPayDate(this.payMethod);
  }

  getAddressById(id: string) {
    return this.addressModels.find((model) => model.equals(id));
  }

  getPayDate(payMethod?: string) {
    switch (payMethod) {
      case PAYMENT_METHOD.bill:
        return this.usance;
      case PAYMENT_METHOD.credit:
        return '';
      case PAYMENT_METHOD.bank:
        return '注文日より14日以内';
      default:
        return '';
    }
  }
}
