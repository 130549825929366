import { signOut } from 'next-auth/react';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { gql, useQuery } from 'urql';
import { Cart } from '../../types/shopify/cart/Cart.type';
import { CartLine } from '../../types/shopify/cart/CartLine.type';
import { Edges } from '../../types/shopify/Edges.type';

const getCart = gql`
  query GetCart($id: ID!, $first: Int) {
    cart(id: $id) {
      estimatedCost {
        subtotalAmount {
          amount
          currencyCode
        }
        totalAmount {
          amount
          currencyCode
        }
      }
      lines(first: $first) {
        edges {
          cursor
          node {
            id
            quantity
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
              totalAmount {
                amount
                currencyCode
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                sku
                price {
                  amount
                  currencyCode
                }
                compareAtPrice {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
                product {
                  handle
                  title
                  vendor
                  featuredImage {
                    url
                    width
                    height
                  }
                  collections(first: 10) {
                    edges {
                      cursor
                      node {
                        handle
                      }
                    }
                  }
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

export interface IUseCart {
  id?: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

/**
 * [名前] 商品検索
 * @param IUseProducts 商品検索の取得数
 */
const useCart = ({ id }: IUseCart) => {
  const alert = useAlert();
  const [{ data, fetching, error }] = useQuery<{
    cart: {
      lines: Edges<CartLine>;
    } & Cart;
  }>({
    query: getCart,
    variables: { id, first: 21 },
    pause: !id,
  });

  useEffect(() => {
    if (id && !fetching && data) {
      if (!data?.cart?.lines) {
        alert.error('セッションが切れました。');
        signOut({ callbackUrl: '/login' });
        localStorage.removeItem('CHECKOUT_ID');
      }
    }
  }, [id, data, fetching, alert]);

  return [{ data, fetching, error }];
};

export default useCart;
