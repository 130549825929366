import PREFECTURES from '../../../constants/AddressConstants';
import { PhoneNumberModel } from '../../../models/customer/PhoneNumberModel';
import { CustomerAddress } from '../../../types/shopify/customers/CustomerAddress.type';

// 都道府県名の英語表記を漢字に変換
const provinceChange = (province?: string) => {
  let label = '';
  PREFECTURES.forEach((p) => {
    if (p.province === province) {
      label = p.label;
    }
  });

  return label;
};

export const convertToHalfWidth = (text: string) => {
  const convertedText = text.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (char) => {
    const code = char.charCodeAt(0) - 0xfee0;
    return String.fromCharCode(code);
  });
  return convertedText;
};

export const getSwapAddress = (address: CustomerAddress) => {
  const swapAddress: CustomerAddress = {
    firstName: address.firstName?.trim(),
    lastName: address.recipientCompany
      ? address.recipientCompany.trim()
      : address.lastName?.trim(),
    country: address.country,
    zip: convertToHalfWidth(address.zip?.trim().replace(/[-−ー]/g, '') || ''),
    province: address.province,
    city: address.city,
    address1: convertToHalfWidth(
      address.address1?.trim().replace(/[−ー]/g, '-') || '',
    ),
    address2: convertToHalfWidth(address.address2?.trim() || ''),
    phone: address.phone
      ? new PhoneNumberModel(address.phone).E164PhoneNumber
      : '',
  };
  return swapAddress;
};

export default provinceChange;
