import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import UplText, { BoldTypes } from '../UplText/UplText';

interface UplButtonProps {
  children: ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: () => void;
  width?: string;
  margin?: string;
  padding?: string;
  disable?: boolean;
  bold?: BoldTypes;
  dataCy?: string;
}

const UplButton: FC<UplButtonProps> = ({
  children,
  onClick,
  width = 'w-60 md:w-52',
  margin = 'mx-auto',
  padding = 'p-3',
  type = 'button',
  disable,
  bold = 'semibold',
  dataCy,
}) => {
  const style = `flex justify-center items-center ${width} ${margin} block ${padding} text-center text-ontheme ${
    disable ? 'bg-gray' : 'bg-theme hover:bg-theme-600'
  } rounded shadow-sm`;
  return (
    <button
      type={type}
      data-testid='UplButton'
      className={style}
      onClick={onClick}
      disabled={disable}
      data-cy={`${dataCy}-button`}
    >
      <UplText variant='text' bold={bold} color='ontheme'>
        {children}
      </UplText>
    </button>
  );
};

interface UplOutLineButtonProps {
  children: ReactNode;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: () => void;
  width?: string;
  margin?: string;
  bold?: BoldTypes;
  dataCy?: string;
}

export const UplOutLineButton: FC<UplOutLineButtonProps> = ({
  children,
  onClick,
  width = 'w-60 md:w-52',
  margin,
  type = 'button',
  bold = 'semibold',
  dataCy,
}) => {
  const style = `${width} ${margin} block p-3 text-center rounded shadow-sm border border-gray bg-white hover:bg-white-gray`;
  return (
    <button
      type={type}
      data-testid='UplOutLineButton'
      className={style}
      onClick={onClick}
      data-cy={`${dataCy}-outline-button`}
    >
      <UplText variant='text' bold={bold} color='description'>
        {children}
      </UplText>
    </button>
  );
};

export default UplButton;
