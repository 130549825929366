const PREFECTURES = [
  { id: '', label: '', province: '' },
  { id: '1', label: '北海道', province: 'Hokkaidō' },
  { id: '2', label: '青森県', province: 'Aomori' },
  { id: '3', label: '岩手県', province: 'Iwate' },
  { id: '4', label: '宮城県', province: 'Miyagi' },
  { id: '5', label: '秋田県', province: 'Akita' },
  { id: '6', label: '山形県', province: 'Yamagata' },
  { id: '7', label: '福島県', province: 'Fukushima' },
  { id: '8', label: '茨城県', province: 'Ibaraki' },
  { id: '9', label: '栃木県', province: 'Tochigi' },
  { id: '10', label: '群馬県', province: 'Gunma' },
  { id: '11', label: '埼玉県', province: 'Saitama' },
  { id: '12', label: '千葉県', province: 'Chiba' },
  { id: '13', label: '東京都', province: 'Tōkyō' },
  { id: '14', label: '神奈川県', province: 'Kanagawa' },
  { id: '15', label: '新潟県', province: 'Niigata' },
  { id: '16', label: '富山県', province: 'Toyama' },
  { id: '17', label: '石川県', province: 'Ishikawa' },
  { id: '18', label: '福井県', province: 'Fukui' },
  { id: '19', label: '山梨県', province: 'Yamanashi' },
  { id: '20', label: '長野県', province: 'Nagano' },
  { id: '21', label: '岐阜県', province: 'Gifu' },
  { id: '22', label: '静岡県', province: 'Shizuoka' },
  { id: '23', label: '愛知県', province: 'Aichi' },
  { id: '24', label: '三重県', province: 'Mie' },
  { id: '25', label: '滋賀県', province: 'Shiga' },
  { id: '26', label: '京都府', province: 'Kyōto' },
  { id: '27', label: '大阪府', province: 'Ōsaka' },
  { id: '28', label: '兵庫県', province: 'Hyōgo' },
  { id: '29', label: '奈良県', province: 'Nara' },
  { id: '30', label: '和歌山県', province: 'Wakayama' },
  { id: '31', label: '鳥取県', province: 'Tottori' },
  { id: '32', label: '島根県', province: 'Shimane' },
  { id: '33', label: '岡山県', province: 'Ōkayama' },
  { id: '34', label: '広島県', province: 'Hiroshima' },
  { id: '35', label: '山口県', province: 'Yamaguchi' },
  { id: '36', label: '徳島県', province: 'Tokushima' },
  { id: '37', label: '香川県', province: 'Kagawa' },
  { id: '38', label: '愛媛県', province: 'Ehime' },
  { id: '39', label: '高知県', province: 'Kochi' },
  { id: '40', label: '福岡県', province: 'Fukuoka' },
  { id: '41', label: '佐賀県', province: 'Saga' },
  { id: '42', label: '長崎県', province: 'Nagasaki' },
  { id: '43', label: '熊本県', province: 'Kumamoto' },
  { id: '44', label: '大分県', province: 'Ōita' },
  { id: '45', label: '宮崎県', province: 'Miyazaki' },
  { id: '46', label: '鹿児島県', province: 'Kagoshima' },
  { id: '47', label: '沖縄県', province: 'Okinawa' },
];

export default PREFECTURES;
