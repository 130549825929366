import Image from 'next/image';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import UplProductSearchBox from '../UplProductSearchBox/UplProductSearchBox';
import headerLogo from '../../../public/Icon/header-logo.png';
import UplHamMenu from '../../atoms/UplHamMenu/UplHamMenu';
import UplCartPreview from '../UplCartPreview/UplCartPreview';
import { Menu, MENU_LOGIN, MENU_LOGOUT } from '../../../constants/HeaderMenues';
import UplSlideMenu from '../UplSlideMenu/UplSlideMenu';
import useCustomerCompany from '../../../hooks/top/useCustomerCompany';
import UplText from '../../atoms/UplText/UplText';
import { PAYMENT_TYPE } from '../../../constants/MyPageConstants';
import useDrawerContext from '../../../hooks/top/useDrawerContext';

const HeaderMenu: React.FC<{ menu: Menu }> = ({
  menu: { label, icon, path, onClick = () => {} },
}) => (
  <div
    role='none'
    className='ml-2 flex w-14 cursor-pointer flex-col items-center whitespace-nowrap rounded text-center hover:bg-theme-600'
    onClick={onClick}
    data-cy={`${path?.replace('/', '')}-icon-container`}
  >
    <Image
      src={icon}
      width={30}
      height={30}
      alt={label}
      data-cy={`${path?.replace('/', '')}-icon`}
    />
    <p className='mt-2 text-xs'>
      <UplText variant='description' color='ontheme' bold='semibold'>
        {label}
      </UplText>
    </p>
  </div>
);
const WelcomeMessage: React.FC<{ name: string }> = ({ name }) => (
  <div className='leading-3'>
    <UplText variant='description' bold='semibold'>
      {name}様
    </UplText>
    <div className='ml-1 inline'>
      <UplText variant='description'>ご利用ありがとうございます!</UplText>
    </div>
  </div>
);

const FreeShippingMessage: React.FC<{
  color: 'danger' | 'white';
  dataCy: string;
}> = ({ color, dataCy }) => (
  <>
    {/* PC */}
    <div
      className='sm:hidden md:hidden'
      data-cy={`free-shipping-message-lg-${dataCy}`}
    >
      {/* 通常配送時 */}
      {/* <UplText variant='h1' color={color} data-cy='10000yen-text'>
        10,000円<span className='text-sm'>(税別)</span>
      </UplText>
      <span className='px-1'>
        <UplText variant='text' color='ontheme'>
          以上お買い上げで
        </UplText>
      </span>
      <UplText variant='h1' color={color}>
        送料無料！
      </UplText> */}
      {/* 送料無料時 */}
      <UplText variant='h1' color={color}>
        期間限定！送料無料キャンペーン実施中！
      </UplText>
    </div>
    {/* タブレット・スマホ */}
    <div
      className='sm:leading-5 lg:hidden'
      data-cy={`free-shipping-message-sm-md-${dataCy}`}
    >
      {/* 通常配送時 */}
      {/* <UplText variant='h2' color={color}>
        10,000円<span className='text-sm'>(税別)</span>
      </UplText>
      <span className='px-1'>
        <UplText variant='subtext' color='ontheme'>
          以上
          <br className='md:hidden' />
          お買い上げで
        </UplText>
      </span>
      <UplText variant='h2' color={color}>
        送料無料！
      </UplText> */}
      {/* 送料無料時 */}
      <UplText variant='h2' color={color}>
        期間限定！送料無料キャンペーン実施中！
      </UplText>
    </div>
  </>
);

const UplDepotHeader: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { data, status } = useSession();
  const authed = status === 'authenticated';
  const isCreditType = data?.user.customer.payType === PAYMENT_TYPE.credit;
  const [{ data: companyData }] = useCustomerCompany({
    customerAccessToken: data?.user.accessToken || '',
  });

  const router = useRouter();
  const [isCartPreviewOpen, setIsCartPreviewOpen] = useState(false);
  const { isDrawerOpen, setIsDrawerOpen } = useDrawerContext();

  const [quantity, setQuantity] = useState(0);

  const createHeaderMenues = (menu: Menu) => {
    const bindMenu = { ...menu };
    const { path = '' } = menu;
    const isCartMenu = path === '/cart';
    const isEstimateMenu = path === '/estimate';
    const style = `absolute top-[-13px] right-[-13px] w-6 h-6 font-semibold leading-6 text-center text-theme bg-white rounded-2xl`;
    if (isCartMenu) {
      bindMenu.onClick = () => setIsCartPreviewOpen((o) => !o);
    } else if (!menu.onClick && path) {
      bindMenu.onClick = () => router.push(path);
    }
    return (
      <div className='relative' key={menu.id}>
        {!(isEstimateMenu && isCreditType) && <HeaderMenu menu={bindMenu} />}
        {isCartMenu && quantity !== 0 && (
          <div className='absolute right-2 top-1'>
            {quantity >= 20 ? (
              <span className={`${style} text-xs`}>20</span>
            ) : (
              <span className={`${style} text-sm`}>{quantity}</span>
            )}
          </div>
        )}
        {isCartMenu && (
          <UplCartPreview
            open={isCartPreviewOpen}
            setOpen={setIsCartPreviewOpen}
            setQuantity={setQuantity}
          />
        )}
      </div>
    );
  };

  return (
    <div data-cy='depot-header'>
      <div className='flex flex-col justify-center border-b-[10px] border-border-on-theme bg-theme px-7 sm:p-2 md:flex md:py-2 lg:flex lg:h-28 lg:pb-2 lg:pt-4'>
        <div className='relative flex items-center justify-between'>
          <div className='relative flex items-center'>
            <div className='lg:hidden'>
              <UplHamMenu onClick={() => setIsDrawerOpen((o) => !o)} />
            </div>
            <div className='sm:ml-4 sm:flex sm:items-end md:ml-6'>
              <Link href='/' passHref>
                <div className='flex cursor-pointer items-center sm:w-28 md:w-40'>
                  <Image
                    src={headerLogo}
                    width={200}
                    className='object-contain'
                    alt='ロゴ'
                    data-cy='header-logo-image'
                  />
                </div>
              </Link>
            </div>
          </div>
          <div className='ml-auto rounded md:hidden lg:hidden'>
            <FreeShippingMessage color='white' dataCy='white' />
          </div>
          <div className='sm:hidden md:hidden lg:w-[550px]'>
            <UplProductSearchBox dataCy='lg' />
          </div>
          <div className='flex items-center text-white sm:hidden'>
            {authed ? (
              <>{MENU_LOGIN.map(createHeaderMenues)}</>
            ) : (
              <>{MENU_LOGOUT.map(createHeaderMenues)}</>
            )}
          </div>
        </div>
      </div>
      <div className='relative h-0 w-full sm:hidden'>
        <div className='absolute right-[20px] top-[-20px] rounded-sm bg-white px-3 py-2 shadow-md'>
          <FreeShippingMessage color='danger' dataCy='red' />
        </div>
      </div>
      <div className='flex flex-col items-start bg-white p-4 sm:justify-center sm:px-2 sm:pt-1 md:pt-2'>
        {authed && (
          <div className='sm:hidden'>
            <WelcomeMessage name={companyData?.customer?.lastName || ''} />
          </div>
        )}
        <div className='mx-auto mt-2 w-11/12 lg:hidden'>
          <UplProductSearchBox dataCy='sm-md' />
        </div>
      </div>
      <UplSlideMenu
        authed={authed}
        open={isDrawerOpen}
        setOpen={setIsDrawerOpen}
        quantity={quantity}
        company={companyData?.customer?.lastName || ''}
        isCreditType={isCreditType}
      >
        {children}
      </UplSlideMenu>
    </div>
  );
};

export default UplDepotHeader;
