import React from 'react';

type Props = {
  onClick: () => void;
};

const UplHamMenu: React.FC<Props> = ({ onClick }) => {
  const barStyle = `block w-6 h-1 first:mt-0 mt-2 rounded-md bg-white`;

  return (
    <div
      onClick={onClick}
      className='ml-2 size-6 cursor-pointer '
      aria-hidden='true'
      data-cy='ham-menu-icon'
    >
      <span className={barStyle} />
      <span className={barStyle} />
      <span className={barStyle} />
    </div>
  );
};

export default UplHamMenu;
