import React, { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import searchIcon from '../../../public/Icon/shop/search.png';

const UplProductSearchBox: React.FC<{ dataCy: string }> = ({ dataCy }) => {
  const [title, setTitle] = useState('');
  const router = useRouter();

  useEffect(() => {
    setTitle('');
  }, [router.asPath]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (router.pathname === '/') {
        router.push({
          pathname: '/',
          query: { ...router.query, sw: title, pi: 0 },
        });
      } else {
        router.push({
          pathname: '/',
          query: { sw: title },
        });
      }
    },
    [router, title],
  );

  return (
    <form
      className='flex items-center md:justify-center'
      onSubmit={handleSubmit}
    >
      <input
        type='text'
        className='rounded-l rounded-r-none border border-gray-200 p-4 outline-none sm:h-10 sm:w-full md:h-10 md:w-2/3 lg:h-16 lg:w-full'
        placeholder='キーワードで検索'
        value={title || ''}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        data-cy={`key-search-text-box-${dataCy}`}
      />
      <button
        type='submit'
        className='cursor-pointer rounded-r bg-dark-gray p-2 text-center hover:bg-dark-gray-300 sm:size-10 md:size-10 lg:h-16 lg:w-14 lg:py-5'
        data-cy={`key-search-button-${dataCy}`}
      >
        <Image
          src={searchIcon}
          width={20}
          height={20}
          alt='検索'
          data-cy='key-search-icon-image'
        />
      </button>
    </form>
  );
};

export default UplProductSearchBox;
