import { FC } from 'react';
import UplText from '../UplText/UplText';

type Props = {
  price: {
    min: number;
    max?: number;
  };
  withinTax?: boolean;
  originPriceLabel?: string; // defaultは通常価格
  priceLabel?: string; // defaultは空文字
  dataCy?: string;
  isPricediscounted?: boolean;
};

const UplPriceTemplate: FC<Props> = ({
  price: { min, max },
  withinTax,
  originPriceLabel = '通常価格',
  priceLabel = '',
  dataCy,
  isPricediscounted,
}) => {
  const minDisplayPrice = Number(min).toLocaleString();
  const maxDisplayPrice = max ? Number(max).toLocaleString() : undefined;
  const existsMaxPrice = max && Number(max) > Number(min);
  const labelSize = isPricediscounted ? 'text-base' : '';
  const customMl = (num: number) => `ml-${num}`;
  return (
    <>
      {existsMaxPrice ? (
        <div className='text-[60%] font-normal leading-none'>
          <span className={labelSize}>
            <UplText
              variant='text'
              color='description'
              dataCy={`max-price-label-${dataCy}`}
            >
              {originPriceLabel}
            </UplText>
          </span>
          <span
            className='mx-1 text-base line-through'
            data-cy={`max-price-container-${dataCy}`}
          >
            <UplText
              variant='text'
              bold='semibold'
              color='description'
              dataCy={`max-price-${dataCy}`}
            >
              ¥{maxDisplayPrice}
            </UplText>
          </span>
          <span className='font-thin'>
            <UplText variant='description' color='description'>
              {withinTax ? '(税込)' : '(税別)'}
            </UplText>
          </span>
        </div>
      ) : null}
      <div
        className={`font-semibold leading-none ${
          isPricediscounted && customMl(4)
        }`}
      >
        <UplText
          variant='text'
          color={existsMaxPrice ? 'danger' : 'primary'}
          dataCy={`min-price-label-${dataCy}`}
        >
          {priceLabel}
        </UplText>
        <span className={`${isPricediscounted && customMl(1)}`}>
          <UplText
            variant='text'
            color={existsMaxPrice ? 'danger' : 'primary'}
            bold='semibold'
            dataCy={`min-price-${dataCy}`}
          >
            ¥{minDisplayPrice}
          </UplText>
          <span className='ml-1 text-[60%] font-thin'>
            <UplText
              variant='description'
              color={existsMaxPrice ? 'danger' : 'primary'}
            >
              {withinTax ? '(税込)' : '(税別)'}
            </UplText>
          </span>
        </span>
      </div>
    </>
  );
};
export default UplPriceTemplate;
