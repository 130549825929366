import Image from 'next/image';
import xIcon from '../../../public/Icon/sns/x.png';
import youTubeIcon from '../../../public/Icon/sns/youTube.png';
import UplText from '../../atoms/UplText/UplText';

const UplDepotFooter: React.FC = () => (
  <div>
    <div className='flex items-center justify-center px-5 pt-5'>
      <a
        target='_blank'
        href='https://x.com/kendepot'
        rel='noreferrer'
        className='mx-4'
      >
        <Image
          src={xIcon}
          className=''
          width={30}
          height={30}
          alt='twitter'
          data-cy='twitter-icon-image'
        />
      </a>
      <a
        target='_blank'
        href='https://www.youtube.com/channel/UCI5tKtbrSsAWPIBj4lHdhCw'
        rel='noreferrer'
        className='mx-4'
      >
        <Image
          src={youTubeIcon}
          className=''
          width={30}
          height={30}
          alt='youtube'
          data-cy='youtube-icon-image'
        />
      </a>
    </div>
    <div className='flex justify-center sm:justify-center md:mt-4 lg:mt-4'>
      <div className='sm:w-28 md:mx-4 lg:mx-4'>
        <UplText variant='text' bold='semibold' color='ontheme'>
          サポート
        </UplText>
        <ul className='mx-2'>
          <li className='mt-2'>
            <UplText
              variant='subtext'
              color='ontheme'
              href='/userguide/about/tos'
              dataCy='userguide-tos-link'
            >
              ご利用規約
            </UplText>
          </li>
          <li className='mt-2'>
            <UplText
              variant='subtext'
              color='ontheme'
              href='/userguide'
              dataCy='userguide-link'
            >
              ご利用ガイド
            </UplText>
          </li>
        </ul>
      </div>
      <div className='relative whitespace-nowrap sm:w-28 lg:mx-4'>
        <UplText variant='text' bold='semibold' color='ontheme'>
          企業情報
        </UplText>
        <ul className='mx-2'>
          <li className='mt-2'>
            <UplText
              variant='subtext'
              color='ontheme'
              href='https://kendepot.co.jp/company'
              externalHref
              dataCy='company-overview-link'
            >
              会社概要
            </UplText>
          </li>
          <li className='mt-2'>
            <UplText
              variant='subtext'
              color='ontheme'
              href='/userguide/about/seller#2'
            >
              古物営業法に基づく表示
            </UplText>
          </li>
          <li className='mt-2'>
            <UplText
              variant='subtext'
              color='ontheme'
              href='https://kendepot.co.jp/privacypolicy'
              externalHref
              dataCy='privacy-policy-link'
            >
              プライバシーポリシー
            </UplText>
          </li>
        </ul>
      </div>
    </div>
    <p className='mt-12 text-center text-xs text-text-on-theme sm:mt-4'>
      Copyright © Ken Depot Corporation, All Rights Reserved.
    </p>
  </div>
);

export default UplDepotFooter;
