import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const UplFooter: React.FC<Props> = ({ children }) => (
  <footer className='z-20 mt-auto h-full border-t-[10px] border-border-on-theme bg-theme pb-2 sm:pb-5 '>
    {children}
  </footer>
);

export default UplFooter;
