import { signIn } from 'next-auth/react';
import { StaticImageData } from 'next/image';
import signUpIcon from '../public/Icon/shop/signup.png';
import loginIcon from '../public/Icon/shop/login.png';
import starIcon from '../public/Icon/shop/star_g.png';
import cartIcon from '../public/Icon/shop/cart.png';
import historyIcon from '../public/Icon/shop/history.png';
import billingIcon from '../public/Icon/shop/billing.png';
import notifyIcon from '../public/Icon/shop/notify.png';
import mypageIcon from '../public/Icon/shop/mypage.png';

export type Menu = {
  id: number;
  label: string;
  icon: StaticImageData;
  path?: string;
  onClick?: (arg?: unknown) => void;
};

export const MENU_LOGOUT: Menu[] = [
  {
    id: 1,
    label: '新規会員登録',
    icon: signUpIcon,
    path: '/signup',
  },
  {
    id: 2,
    label: 'ログイン',
    icon: loginIcon,
    path: '/login',
    onClick: () => signIn(),
  },
];

export const MENU_LOGIN: Menu[] = [
  {
    id: 1,
    label: 'お気に入り',
    icon: starIcon,
    path: '/favorite',
  },
  {
    id: 2,
    label: 'カート',
    icon: cartIcon,
    path: '/cart',
  },
  {
    id: 3,
    label: '見積一覧',
    icon: billingIcon,
    path: '/estimate',
  },
  {
    id: 4,
    label: '注文履歴',
    icon: historyIcon,
    path: '/history',
  },
  {
    id: 5,
    label: 'お知らせ',
    icon: notifyIcon,
    path: '/notify',
  },
  {
    id: 6,
    label: 'マイページ',
    icon: mypageIcon,
    path: '/mypage',
  },
];
