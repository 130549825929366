import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { convertToHalfWidth } from '../../modules/util/customer/address';

const region = 'JP';
export class PhoneNumberModel {
  private number: string;
  private util: PhoneNumberUtil;

  constructor(number: string) {
    //数字やハイフン系の記号を半角に統一
    this.number = convertToHalfWidth(
      number.replace(/[\u002D\u30FC\u2010-\u2015\u2212\uFF70]/gu, '-') || '',
    );
    this.util = PhoneNumberUtil.getInstance();
  }

  private get phoneNumber() {
    return this.util.parseAndKeepRawInput(this.number, region);
  }

  //画面表示用(日本形式)
  get displayPhoneNumber() {
    return this.util.format(this.phoneNumber, PhoneNumberFormat.NATIONAL);
  }

  //データ保存形式(E164)
  get E164PhoneNumber() {
    return this.util.format(this.phoneNumber, PhoneNumberFormat.E164);
  }
}
