import Link from 'next/link';
import React, { FC, useMemo, useState } from 'react';

const Options = {
  xl: {
    font: 'text-4xl',
    color: 'text-dark-gray-300',
    bold: 'font-bold',
  },
  h1: {
    font: 'lg:text-2xl md:text-2xl sm:text-xl',
    color: 'text-dark-gray-300',
    bold: 'font-bold',
  },
  h2: {
    font: 'lg:text-lg md:text-lg sm:text-base',
    color: 'text-dark-gray',
    bold: 'font-semibold',
  },
  text: {
    font: ['text-md'],
    color: 'text-dark-gray',
    bold: 'font-medium',
  },
  subtext: {
    font: ['text-sm'],
    color: 'text-gray-700',
    bold: 'font-medium',
  },
  description: {
    font: ['text-xs'],
    color: 'text-gray-300',
    bold: 'font-medium',
  },
  tiny: {
    font: ['text-[9px]'],
    color: 'text-gray-300',
    bold: 'font-thin',
  },
};
export type VariantTypes = keyof typeof Options;
const Colors = {
  primary: 'text-dark-gray',
  description: 'text-gray-300',
  danger: 'text-red',
  success: 'text-green',
  white: 'text-white',
  link: 'text-theme-600',
  ontheme: 'text-text-on-theme',
};

export type ColorTypes = keyof typeof Colors;
const BgColors: { [key in ColorTypes]: string } = {
  primary: 'bg-dark-gray',
  description: 'bg-gray-300',
  danger: 'bg-red',
  success: 'bg-green',
  white: 'bg-white',
  link: 'bg-theme-600',
  ontheme: 'bg-text-on-theme',
};

const Bold = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  medium: 'font-medium',
  none: 'font-normal',
};
export type BoldTypes = keyof typeof Bold;

interface UplTextProps {
  variant: VariantTypes;
  color?: ColorTypes;
  bold?: BoldTypes;
  link?: boolean;
  href?: string;
  externalHref?: boolean;
  dataCy?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const UplText: FC<UplTextProps> = ({
  variant,
  color,
  bold,
  children,
  link,
  href,
  externalHref,
  dataCy,
  onClick,
}) => {
  const [hover, setHover] = useState(false);

  const underLineClasses = useMemo(
    () => [
      'h-[1px]',
      'my-0',
      'mx-auto',
      'absolute',
      'left-0',
      'right-0',
      'bottom-[-2px]',
      'transition-all',
      'duration-300',
      color ? BgColors[color] : BgColors.link,
      hover ? `w-full opacity-100` : `w-0 opacity-30`,
    ],
    [color, hover],
  );

  return (
    <span
      data-testid='UplText'
      className={` ${Options[variant].font} ${
        color ? Colors[color] : Options[variant].color
      } ${bold ? Bold[bold] : Options[variant].bold} ${
        (!!href || link) && 'relative cursor-pointer'
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-cy={dataCy}
    >
      <span onClick={onClick} role='none'>
        {href && !externalHref && (
          <Link href={href} passHref>
            <span>{children}</span>
          </Link>
        )}
        {href && externalHref && (
          <a href={href} target='_blank' rel='noreferrer'>
            {children}
          </a>
        )}
        {!href && children}
      </span>
      {(!!href || link) && (
        <div
          className={`${underLineClasses.join(' ')}`}
          data-cy={`${dataCy}-under-line`}
        />
      )}
    </span>
  );
};

export default UplText;
