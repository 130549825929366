import React, { FC, ReactNode } from 'react';
import UplText from '../UplText/UplText';

interface UplBarTitleProps {
  children: ReactNode;
}

const UplBarTitle: FC<{ width?: string } & UplBarTitleProps> = ({
  children,
  width = 'w-full',
}) => {
  const style = `${width} pb-2 text-lg font-semibold border-b-2 border-white-gray text-dark-gray`;
  return <h4 className={style}>{children}</h4>;
};

export const UplMenubarTitle: React.FC<UplBarTitleProps> = ({ children }) => (
  <div className='border-y border-gray bg-white-gray p-2'>
    <UplText variant='subtext' color='description'>
      {children}
    </UplText>
  </div>
);

export default UplBarTitle;
