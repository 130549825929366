import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import useCart from '../../../hooks/cart/useCart';
import UplButton from '../../atoms/UplButton/UplButton';
import UplText from '../../atoms/UplText/UplText';
import UplOrderProductPreview from '../UplOrderProductPreview/UplOrderProductPreview';
import { PAYMENT_TYPE } from '../../../constants/MyPageConstants';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setQuantity: Dispatch<SetStateAction<number>>;
};

const UplCartPreview: FC<Props> = ({ open, setOpen, setQuantity }) => {
  const { data: session } = useSession();
  const cartId = session?.user.cart.id;
  const [{ data: cartData }] = useCart({ id: cartId });
  const isCreditType = session?.user.customer.payType === PAYMENT_TYPE.credit;

  const router = useRouter();

  useEffect(() => {
    setQuantity(cartData?.cart?.lines?.edges?.length || 0);
  }, [cartData, setQuantity]);

  const style = `${
    open ? 'visible opacity-100' : 'invisible opacity-0'
  } absolute top-16 -left-10 z-50 shadow-md md:w-2/7 md:top-20 bg-white border border-gray rounded
  after:allows-top-white after:absolute after:-top-[11px] after:left-[65px] after:content-[""]  `;
  return (
    <div>
      <div className={style} data-cy='cart-preview'>
        {cartData?.cart?.lines.edges.length !== 0 ? (
          <div className='py-4'>
            <div className='max-h-96 w-80 overflow-y-auto'>
              <UplOrderProductPreview lines={cartData?.cart?.lines.edges} />
            </div>
            <div className='flex flex-col border-t border-gray px-4'>
              <UplButton
                onClick={() => router.push('/cart')}
                margin='mt-4 mx-auto'
                width='w-full'
                dataCy='cart-preview-cart'
              >
                カート詳細へ
              </UplButton>
              <UplButton
                onClick={() => router.push('/register')}
                margin='mt-4 mx-auto'
                width='w-full'
                dataCy='cart-preview-order'
              >
                ご注文手続きへ
              </UplButton>
              {!isCreditType && (
                <UplButton
                  onClick={() => router.push('/estimate/register')}
                  margin='mt-4 mx-auto'
                  width='w-full'
                  dataCy='cart-preview-estimate'
                >
                  まずはお見積
                </UplButton>
              )}
              <div
                className='mt-2 text-right'
                onClick={() => {
                  setOpen(false);
                }}
                aria-hidden
              >
                <UplText
                  variant='subtext'
                  color='primary'
                  link
                  dataCy='cart-preview-cancel-link'
                >
                  キャンセル
                </UplText>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex w-80 items-center justify-between py-6 pl-4 pr-8 text-text-on-theme'>
            <p>
              <UplText variant='subtext'>
                カート内に商品はございません。
              </UplText>
            </p>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
              onClick={() => setOpen(false)}
              className='relative cursor-pointer'
              data-cy='cart-preview-cancel-icon'
            >
              <div className='after:absolute after:w-4 after:rotate-45 after:rounded after:border-b-2 after:border-black after:content-[""]' />
              <div className='before:absolute before:w-4 before:-rotate-45 before:rounded before:border-b-2 before:border-black before:content-[""]' />
            </div>
          </div>
        )}
      </div>
      {open && (
        <div
          className='fixed left-0 top-0 z-40 h-screen w-full'
          aria-hidden
          onClick={() => setOpen(false)}
        />
      )}
    </div>
  );
};

export default UplCartPreview;
