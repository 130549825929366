import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { MdCancel } from 'react-icons/md';
import { UplMenubarTitle } from '../../atoms/UplBarTitle/UplBarTitle';
import { Menu, MENU_LOGIN, MENU_LOGOUT } from '../../../constants/HeaderMenues';
import UplText from '../../atoms/UplText/UplText';
import homeIcon from '../../../public/Icon/shop/home.png';

interface UplSlideMenuProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  authed: boolean;
  quantity: number;
  company: string;
  children: React.ReactNode;
  isCreditType: boolean;
}

const WelcomeMessage: React.FC<{ name: string }> = ({ name }) => (
  <div className='leading-3'>
    <UplText variant='description' bold='semibold' color='white'>
      {name}様
    </UplText>
    <div>
      <UplText variant='description' color='white'>
        ご利用ありがとうございます!
      </UplText>
    </div>
  </div>
);

const UplSlideMenu: React.FC<UplSlideMenuProps> = ({
  open,
  setOpen,
  authed,
  children,
  quantity,
  company,
  isCreditType,
}) => {
  const router = useRouter();
  const visibleDuration = open
    ? 'visible duration-[300ms]'
    : 'invisible duration-[300ms] opacity-0';
  const style = `${
    open ? 'translate-x-0 opacity-100' : '-translate-x-80'
  } ${visibleDuration} fixed z-50 top-0 left-0 h-full w-full`;

  const drawerBackground = `fixed top-0 z-40 w-full h-screen bg-black opacity-50 ${visibleDuration}`;

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (open) {
        body.style.overflowY = 'hidden';
      } else {
        body.style.overflowY = 'auto';
      }
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const createCommonMenu = (menu: Menu) => {
    const bindMenu = { ...menu };
    const { path = '' } = menu;
    const badgeStyle = `absolute -top-3.5 -right-3 w-6 h-6 font-semibold leading-6 text-center text-white bg-theme rounded-2xl`;
    const isEstimateMenu = path === '/estimate';
    if (!menu.onClick && path) {
      bindMenu.onClick = () => router.push(path);
    }
    return (
      !(isEstimateMenu && isCreditType) && (
        <div className='relative' key={menu.id}>
          <div
            role='none'
            className='flex w-full cursor-pointer items-center px-6 py-3 text-left hover:bg-theme-100'
            onClick={bindMenu.onClick}
            data-cy={`slide-menu-${path.replace('/', '') || 'home'}`}
          >
            <div className='relative size-7'>
              <Image
                src={bindMenu.icon}
                fill
                className='object-contain'
                alt={bindMenu.label}
              />
              {path === '/cart' && quantity !== 0 && (
                <p>
                  {quantity >= 100 ? (
                    <span className={`${badgeStyle} text-xs`}>99+</span>
                  ) : (
                    <span
                      className={`${badgeStyle} text-sm`}
                      data-cy='cart-item-count'
                    >
                      {quantity}
                    </span>
                  )}
                </p>
              )}
            </div>
            <span className='ml-4'>
              <UplText variant='subtext'>{bindMenu.label}</UplText>
            </span>
          </div>
        </div>
      )
    );
  };

  return (
    <div className='lg:hidden'>
      <div className={style} data-cy='slide-menu'>
        <div className='relative left-0 top-0 z-50 h-screen sm:top-0 sm:max-h-[800px] sm:w-11/12 md:max-h-[800px] md:w-96'>
          <div
            className='absolute left-0 top-0 h-screen w-11/12 overflow-y-scroll bg-white shadow-md sm:pb-20 md:pb-20'
            data-cy='main-menu'
          >
            <div className='bg-theme px-4 py-2 md:hidden'>
              <WelcomeMessage name={company} />
            </div>
            <UplMenubarTitle>メインメニュー</UplMenubarTitle>
            {authed ? (
              <>
                {createCommonMenu({
                  label: 'ホーム',
                  id: 9999,
                  path: '/',
                  icon: homeIcon,
                })}
                {MENU_LOGIN.map(createCommonMenu)}
              </>
            ) : (
              <>{MENU_LOGOUT.map(createCommonMenu)}</>
            )}
            {children}
          </div>
          <div
            className='absolute -right-4 top-1 cursor-pointer text-5xl text-white opacity-50 hover:opacity-70 sm:-right-5'
            onClick={() => setOpen(false)}
            role='button'
            tabIndex={0}
            aria-hidden
            data-cy='close-icon'
          >
            <MdCancel />
          </div>
        </div>
        <div
          onClick={() => setOpen(false)}
          className='absolute top-0 z-10 h-screen w-full '
          aria-hidden
        />
      </div>
      <div className={drawerBackground} />
    </div>
  );
};

export default UplSlideMenu;
