import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const UplHeader: React.FC<Props> = ({ children }) => (
  <header className='z-30 h-auto border-b border-white-gray'>{children}</header>
);

export default UplHeader;
