import Image from 'next/image';
import React, { FC } from 'react';
import { CartLine } from '../../../types/shopify/cart/CartLine.type';
import { Edges } from '../../../types/shopify/Edges.type';
import UplPriceTemplate from '../../atoms/UplPriceTemplate/UplPriceTemplate';
import UplText from '../../atoms/UplText/UplText';

interface UplOrderProductPreviewProps {
  lines?: Edges<CartLine>['edges'];
}

const UplOrderProductPreview: FC<UplOrderProductPreviewProps> = ({
  lines = [],
}) => (
  <>
    {React.Children.toArray(
      lines.map(({ node }, lidx) => {
        const product = node?.merchandise?.product;
        return (
          <>
            <div
              className='mt-2 flex items-center justify-between px-4 pb-2 leading-3'
              key={node.id}
              data-cy='order-product-preview'
            >
              <div className='relative size-20'>
                {product?.featuredImage?.url ? (
                  <Image
                    src={product?.featuredImage?.url}
                    alt='商品画像'
                    fill
                    className='object-contain'
                    unoptimized
                  />
                ) : (
                  <div className='flex size-20 items-center justify-center bg-gray text-white'>
                    No Image
                  </div>
                )}
              </div>
              <div className='w-60 px-2'>
                <div className='mt-2 line-clamp-2 w-full lg:mt-0'>
                  <UplText
                    variant='subtext'
                    bold='semibold'
                    color='primary'
                    href={`/product/${product?.handle}`}
                  >
                    {product?.title}
                  </UplText>
                </div>
                {node.merchandise.selectedOptions?.map(
                  (option, idx) =>
                    option.name !== 'Title' && (
                      <UplText variant='description' key={option.name}>
                        {`${idx !== 0 ? ' | ' : ''}${option.value}`}
                      </UplText>
                    ),
                )}
                <div className='mt-2 flex items-end'>
                  <UplText variant='description'>{node.quantity}個</UplText>
                </div>
                <div className='flex items-end'>
                  <UplPriceTemplate
                    price={{
                      min: node.cost?.subtotalAmount?.amount || 0,
                    }}
                  />
                </div>
              </div>
            </div>
            {lidx !== lines.length - 1 && (
              <div className='mx-auto mb-4 w-11/12 border-b border-gray pb-2' />
            )}
          </>
        );
      }),
    )}
  </>
);

export default UplOrderProductPreview;
